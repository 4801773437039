
<!-- Toolbar -->
<mat-toolbar color="primary" style="justify-content: space-between;">
  <a class="logo" [routerLink]="['/']" routerLinkActive="router-link-active">
    <span>Stream</span>
    <svg version="1.1" class="icon_logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" viewBox="0 0 512 512">
      <g>
      </g>
        <path d="M349.296 86.927l28.283 97.813 101.817-3.236-84.275 57.18 34.581 95.805-80.404-62.485-80.496 62.485 34.539-95.805-84.245-57.18 101.765 3.236z" fill="#000000" />
        <path d="M228.045 387.881l51.292-142.224-125.113-84.889 151.112 4.854 42.066-145.214-68.567 108.964-246.231-7.885 203.879 138.353-83.661 231.752 194.581-151.143 119.399 47.432-119.399-92.774z" fill="#000000" />
    </svg>
  </a>

  <!-- <div class="d-f-c">
    <mat-icon class="mr-8">brightness_5</mat-icon>
    <mat-slide-toggle [formControl]="toggleControl" class="mr-8">
    </mat-slide-toggle>
    <mat-icon>bedtime</mat-icon>
  </div> -->
  <div *ngIf="user_id == 0" class="user" style="height: 24px;">
    <a class="nav_link" title="{{ 'Log_in' | translate }}" href="{{ host }}/user/auth/?action=lastauth&amp;returnUrl={{ _window.location.href }}{{ invite }}" >
        <mat-icon style="color: #fff;">login</mat-icon>
    </a>
  </div>
  <div *ngIf="user_id != 0" class="user_menu" (click)="$event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault();">
    <button mat-button [matMenuTriggerFor]="userMenu" id="languages-dropdown">
        <ng-container *ngIf="!!user?.image; else elseTemplate">
            <div class="user_photo" [ngStyle]="{'background': 'url(' + user?.image + ') no-repeat center / cover'}"></div>
        </ng-container>
        <ng-template #elseTemplate>
            <mat-icon style="width: 36px; height: 36px; font-size: 36px; line-height: 36px;">account_circle</mat-icon>
        </ng-template>
    </button>

    <mat-menu #userMenu="matMenu" xPosition="before" class="header_menu">

        <button mat-menu-item [matMenuTriggerFor]="languageMenu" id="mob-languages-dropdown">
            <div class="d-f-c">
                <mat-icon>language</mat-icon>
                <span style="background-color: transparent !important;">{{ activeLang }}</span> 
            </div>
        </button>            
        <!-- <button mat-menu-item *ngIf="!!user.is_root" (click)="closeAllDialogs(); goToRoot()">
            <mat-icon>security</mat-icon>
            <span style="background-color: transparent !important;">{{ "Global settings" | translate }}</span> 
        </button>            
        <button mat-menu-item (click)="closeAllDialogs(); goToConnectedApps($event)">
            <mat-icon>settings</mat-icon>
            <span style="background-color: transparent !important;">{{ "Settings" | translate }}</span> 
        </button>
        <a mat-menu-item href="/set" [routerLink]="['/set']" routerLinkActive="router-link-active">
            <mat-icon>add</mat-icon>
            <span style="background-color: transparent !important;">{{ "Set up new Workspace" | translate }}</span> 
        </a>      -->
        <!-- <a href="/all-tasks?v2=1&order_by=updated_desc&count=1" mat-menu-item class="mat-menu-item d-f-c" (click)='closeAllDialogs(); goToAllCompany($event)'>
            <mat-icon>assignment</mat-icon>
            <span class="underline">
                {{ "All tasks" | translate }}
            </span>
        </a> -->
        <div class="mat-menu-item d-f-c" style="display: flex !important; align-items: center !important;">
            <ng-container *ngIf="!!user?.image; else elseTemplate">
                <div class="user_photo" [ngStyle]="{'background': 'url(' + user?.image + ') no-repeat center / cover'}"></div>
            </ng-container>
            <ng-template #elseTemplate>
                <mat-icon>account_circle</mat-icon>
            </ng-template>
            <span>{{ user?.display_name }}</span>
        </div>
        <div class="mat-menu-item d-f-c">
            <mat-icon>email</mat-icon>
            <span>{{ user?.email }}</span>
        </div>
        
        <!-- <div class="mat-menu-item d-f-c" (click)="getSubscription(user?.web_push_public_key)">
            <mat-icon>notifications_active</mat-icon>
            <span>{{ "Allow Notifications" | translate }}
                <ng-container [ngSwitch]="webPushStage">
                    <ng-container *ngSwitchCase="1">
                        ({{"In process." | translate}})
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                        ({{"In process.." | translate}})
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                        ({{"Success" | translate}})
                    </ng-container>
                    <ng-container *ngSwitchCase="4">
                        ({{"Error" | translate}})
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                    
                    </ng-container>
                </ng-container>
            </span>
        </div> -->
        <a mat-menu-item href="{{host}}/user/auth/?action=update&returnUrl={{_window.location.href}}" (click)='closeAllDialogs(); menuHref($event, "/user/auth/?action=update", true)'>
            <mat-icon>manage_accounts</mat-icon>
            <span class="underline">
                {{ 'Edit_account' | translate }}
            </span>
        </a>
        <a mat-menu-item href="{{host}}/user/auth/?returnUrl={{_window.location.origin}}" (click)='closeAllDialogs(); menuHref($event, "/user/auth/", false)'>
            <mat-icon>people_alt</mat-icon>
            <span class="underline">
                {{ 'Change_user' | translate }}
            </span>
        </a>
        <a mat-menu-item href="{{host}}/user/logout/?returnUrl={{_window.location.origin}}" (click)='closeAllDialogs(); menuHref($event, "/user/logout/", false)'>
            <mat-icon>logout</mat-icon>
            <span class="underline">
                {{ 'Log_out' | translate }}
            </span>
        </a>
    </mat-menu>

    <mat-menu #languageMenu="matMenu" xPosition="before" class="header_menu">
        <button *ngFor="let lang of languages"
        (click)="selectLang(lang)"
        mat-menu-item
        name="language" 
        value="{{ lang }}" 
        class="mob_lang_btns"
        >
            <div class="lang_photo" [ngStyle]="{'background': 'url(' + imgRoute + 'assets/img/lang/' + lang + '.svg' + ') no-repeat center / contain'}"></div>
            <span>{{ lang }}</span>
        </button>
    </mat-menu>
  </div>
</mat-toolbar>


<div class="content" role="main">
  <mat-card class="example-card" style="width: 50%;">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image">
        <svg style="width: 100%; height: 100%;" id="rocket" xmlns="http://www.w3.org/2000/svg" width="101.678" height="101.678" viewBox="0 0 101.678 101.678">
          <title>Rocket Ship</title>
          <g id="Group_83" data-name="Group 83" transform="translate(-141 -696)">
            <circle id="Ellipse_8" data-name="Ellipse 8" cx="50.839" cy="50.839" r="50.839" transform="translate(141 696)" fill="#dd0031"/>
            <g id="Group_47" data-name="Group 47" transform="translate(165.185 720.185)">
              <path id="Path_33" data-name="Path 33" d="M3.4,42.615a3.084,3.084,0,0,0,3.553,3.553,21.419,21.419,0,0,0,12.215-6.107L9.511,30.4A21.419,21.419,0,0,0,3.4,42.615Z" transform="translate(0.371 3.363)" fill="#fff"/>
              <path id="Path_34" data-name="Path 34" d="M53.3,3.221A3.09,3.09,0,0,0,50.081,0,48.227,48.227,0,0,0,18.322,13.437c-6-1.666-14.991-1.221-18.322,7.218A33.892,33.892,0,0,1,9.439,25.1l-.333.666a3.013,3.013,0,0,0,.555,3.553L23.985,43.641a2.9,2.9,0,0,0,3.553.555l.666-.333A33.892,33.892,0,0,1,32.647,53.3c8.55-3.664,8.884-12.326,7.218-18.322A48.227,48.227,0,0,0,53.3,3.221ZM34.424,9.772a6.439,6.439,0,1,1,9.106,9.106,6.368,6.368,0,0,1-9.106,0A6.467,6.467,0,0,1,34.424,9.772Z" transform="translate(0 0.005)" fill="#fff"/>
            </g>
          </g>
        </svg>
      </div>
      <mat-card-title>{{ title }}</mat-card-title>
      <mat-card-subtitle>The app will be coming soon!</mat-card-subtitle>
    </mat-card-header>
    <!-- <mat-card-content>
      <p>Welcome to {{title}}, the ultimate platform for all your live streaming needs! Whether you're a gamer, a musician, a fitness instructor, or a business professional, {{title}} provides you with a user-friendly, robust, and high-quality streaming experience.</p>
        
      <h2>Key Features:</h2>
      <ul class="feature-list">
          <li><strong>High-Quality Streaming:</strong> Enjoy crystal-clear video and audio, ensuring your audience gets the best possible viewing experience.</li>
          <li><strong>User-Friendly Interface:</strong> Navigate through our intuitive platform with ease, making streaming a breeze for beginners and professionals alike.</li>
          <li><strong>Multi-Platform Integration:</strong> Stream simultaneously across multiple platforms, including YouTube, Facebook, and Twitch, to reach a broader audience.</li>
          <li><strong>Real-Time Analytics:</strong> Gain insights into your stream's performance with real-time analytics, helping you understand your audience and improve your content.</li>
          <li><strong>Interactive Tools:</strong> Engage with your viewers through live chats, polls, and Q&A sessions, making your streams more interactive and engaging.</li>
          <li><strong>Customizable Settings:</strong> Tailor your streaming experience with customizable settings for video quality, privacy, and notifications.</li>
      </ul>
      
      <p>Join {{title}} today and take your live streaming to the next level. Share your passions, connect with your audience, and grow your community with ease. Welcome to the future of live streaming!</p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button color="warn">LIKE</button>
      <button mat-button color="accent">Just BTN</button>
      <button mat-raised-button color="primary" (click)="showDialog()">
        Open Dialog
      </button>
    </mat-card-actions> -->
  </mat-card>
</div>

<router-outlet></router-outlet>
