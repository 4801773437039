import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: "root"
})

export class HttpRestService {

    constructor(
        private readonly _httpClient: HttpClient,
    ) {}

    public executeGet<T>(path: string, includeAuth: boolean = true, options: any = {}): Observable<T> {
        return this._httpClient.get<T>(`${environment.host}${path}`, this._prepareHttpRequestOptions(options, includeAuth)).pipe<T>(
            catchError(error => this._handleError(error))
        )
    }

    public executePost<T>(path: string, body: any, includeAuth: boolean = true, options: any = {}): Observable<T> {
        return this._httpClient.post<T>(`${environment.host}${path}`, body, this._prepareHttpRequestOptions(options, includeAuth)).pipe<T>(
            catchError(error => this._handleError(error))
        )
    }

    public executePut<T>(path: string, body: any, includeAuth: boolean = true, options: any = {}): Observable<T> {
        return this._httpClient.put<T>(`${environment.host}${path}`, body, this._prepareHttpRequestOptions(options, includeAuth)).pipe<T>(
            catchError(error => this._handleError(error))
        )
    }

    public executeDelete<T>(path: string, includeAuth: boolean = true, options: any = {}): Observable<T> {
        return this._httpClient.delete<T>(`${environment.host}${path}`, this._prepareHttpRequestOptions(options, includeAuth)).pipe<T>(
            catchError(error => this._handleError(error))
        )
    }

    private _prepareHttpRequestOptions(options: any, includeAuth: boolean) {
        let _resultOptions: any = {};
        if (options) {
            _resultOptions = Object.assign({}, options);
            _resultOptions.headers = this._setHttpHeaders(_resultOptions.headers, includeAuth);
            _resultOptions.withCredentials = true;
            _resultOptions.reportProgress = true;
            return _resultOptions;
        }
    }

    private _setHttpHeaders(headers: HttpHeaders, includeAuth: boolean) {
        if (!headers) {
            headers = new HttpHeaders();
        }
    }

    private _handleError(error: any): Observable<any> {
        console.log(error)
        return throwError(error);
    }
}