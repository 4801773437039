import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RefreshService } from '../services/rest/refresh.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private refreshService: RefreshService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
            // if (err.status === 401) {
            //     this.authenticationService.logout();
            //     location.reload(true);
            // }

                console.log(err);

                if (err.status === 429) {
                    this.router.navigate(['/error-page']);
                }

                let error;
                if (!!err.error[0]) {
                    if (err.error[0].message == 'The directory or file exists' || err.error[0].message == 'You can not publish content at this time. Please, choose another time. (30 minutes spacing required)') {
                        error = err.error;
                    } else {
                        error = err.error[0].message;
                    }
                } else {
                    error = err.error.message || err.statusText;
                }

                if (err.url.indexOf("/static/privacy/") != -1) {
                    error = err
                }

                console.log("intercept err", err)
                if (!!err.error.name) {
                    this.refreshService.forceRequest$.next({
                        request: request,
                        err: err
                    })
                }   
                return throwError(error);
            }
        ))
    }
}