import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { HttpRestService } from './http-rest.service';
import { StorageManagerService } from './storage-manager.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public languageData$: BehaviorSubject<any> = new BehaviorSubject<any>({
      "active": 'en',
      "languages": ["en","ru","es","uk"]
  });

  constructor(
    private http: HttpRestService,
    private meta: Meta,
    private sm: StorageManagerService
    ) { }

  public getLangData() {
    return this.languageData$.asObservable();
  }

  setLanguage(lang) {
    return this.http.executePost<any>(`/language/select/`, {
      'language': lang
    });
  }

  public getLocalLanguage() {
    return this.http.executeGet("/site/init/languages/")
  }

  public getLang() {
    return JSON.parse(this.meta.getTag('name=lang').content);
  }
}