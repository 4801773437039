import { Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { of } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class StorageManagerService {

    private static readonly DBKEY_USER_DATA = 'user_data';

    constructor(private meta: Meta) {}

    public getImgPath() {
        return of(this.meta.getTag('name=assets_path').content);
    }

    public getHost() {
        return of(environment.host);
    }

    public getMeta() {
        return of(this.meta.getTag('name=meta').content);
    }

    public getData(key = StorageManagerService.DBKEY_USER_DATA) {
        let data = this.sessionStorageGetItem(key);
        if (data == null) {
            data = this.localStorageGetItem(key);
        }
        return data;
    }

    public getDataObject(key = StorageManagerService.DBKEY_USER_DATA) {
        let data = this.getData(key);
        if (data != null) {
            return data;
        } else {
            return null;
        }
    }

    public exist(key = StorageManagerService.DBKEY_USER_DATA) {
        let data = sessionStorage.getItem(key);
        if (data == null) {
            data = localStorage.getItem(key);
        }
        return data != null
    }

    public saveSessionData(data: any, key = StorageManagerService.DBKEY_USER_DATA) {
        localStorage.removeItem(key);
        this.sessionStorageSetItem(key, data)
    }

    public localStorageSetItem(key: string, data: any) {
        localStorage.setItem(key, typeof data === 'object' ? JSON.stringify(data) : data)
    }

    public sessionStorageSetItem(key: string, data: any) {
        sessionStorage.setItem(key, typeof data === 'object' ? JSON.stringify(data) : data)
    }

    public localStorageGetItem(key: string) {
        return localStorage.getItem(key)
    }

    public sessionStorageGetItem(key: string) {
        return sessionStorage.getItem(key)
    }
}