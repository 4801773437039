import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StorageManagerService } from "../services/common/storage-manager.service";


@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

    constructor(private readonly _storageManager: StorageManagerService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let reqHeaders = req.headers
        if (req.headers.get("content-type") == 'multipart/form-data') {
            reqHeaders = reqHeaders.delete("content-type")
            req = req.clone({
                headers: reqHeaders,
                withCredentials: false,
            });
            return next.handle(req);
        }
        
        reqHeaders = reqHeaders
            .set('Access-Control-Allow-Origin', '*')
            .set('Access-Control-Allow-Credentials', 'true')
            .set('Access-Control-Allow-Headers', '*')
            .set('X-Requested-With', 'XMLHttpRequest')
            

        if (req.url && req.url.indexOf('/site/online/') != -1) {
            reqHeaders = reqHeaders.set('ngsw-bypass', 'true');
        }

        const csrfToken = this._storageManager.localStorageGetItem('csrf_token');

        if (!!csrfToken) {
            reqHeaders = reqHeaders.set('X-CSRF-Token', csrfToken);
        }
        
        req = req.clone({
            headers: reqHeaders,
            withCredentials: true,
        });
        // body: req?.body
        
        return next.handle(req);
    }

}

