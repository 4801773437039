import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public $user: BehaviorSubject<any> = new BehaviorSubject({});
  public $userToken: BehaviorSubject<any> = new BehaviorSubject({});
  constructor(
    private http: HttpRestService,
    private meta: Meta
    ) {}

  getToken(data) {
    return this.http.executePost<any>('/site/online/', data);
  }

  getUser() {
    return this.$user.asObservable();
  }
  
  getUserToken() {
    return this.$userToken.asObservable();
  }
  
  getInitData() {
    return this.http.executeGet<any>('/site/init/')
  }

  getNotifications(page, filter?) {
    let filter_params = new HttpParams().set('expand','discussion,discussionPost,discussionPost.employee,imageFile').set('per-page', '50').set('page', page)

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
    return this.http.executeGet<any>('/api/notification/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  editNotification(id, data) {
    return this.http.executePost<any>(`/api/notification/${id}/`, data)
  }

  getInitMetaDev(meta_type, meta_acm) {
    return this.http.executeGet<any>('/site/init/', true, {
      params: new HttpParams().set("meta_type", meta_type).set("meta_acm", meta_acm)
    })
  }

  getInitMeta() {
    return of({meta: JSON.parse(this.meta.getTag('name=meta').content)});
  }

  getMetaInitData() {
    return JSON.parse(this.meta.getTag('name=user').content);
  }
}
