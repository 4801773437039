import { SubscriptionLike } from 'rxjs';

export abstract class BaseClass {
  private elementSubscriptions: Array<SubscriptionLike>;

  constructor() {
    this.elementSubscriptions = new Array<SubscriptionLike>();
  }

  protected attachSubscriptions(...subscriptions: Array<SubscriptionLike>) {
    this.elementSubscriptions.push(...subscriptions);
  }

  protected clearSubscriptions() {
    this.elementSubscriptions.forEach((s) => s.unsubscribe());
    this.elementSubscriptions = [];
  }
}