import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SampleDialogComponent } from './components/sample-dialog/sample-dialog.component';
import { BaseClass } from './shared/models/base-class';
import { AuthService } from './shared/services/rest/auth.service';
import { fromEvent, Subscription } from 'rxjs';
import { LanguageService } from './shared/services/common/language.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { StorageManagerService } from './shared/services/common/storage-manager.service';
import { CompanyService } from './shared/services/rest/company.service';
import { TaskService } from './shared/services/rest/task.service';
import { GlobalDataService } from './shared/services/common/global-data.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseClass implements OnInit, OnDestroy {
  title = 'StreamStar';

  @HostBinding('class') className = '';
  public is_mobile: boolean = false;
  toggleControl = new FormControl(false);
  private sub: Subscription;
  public user: any;
  public user_id: number;
  public _window = window;
  public envVar = environment;
  public host: any = this.envVar.host;
  public activeLang: any;
  public isGetGlobalData: boolean = false;
  public isInit: boolean = false;
  public company: any;
  public imgRoute: any = "";
  public invite: any = "";
  public languages: string[];
  public companyForm: FormGroup = this.fb.group({
    companyID: 0
  })
  constructor(
    private dialog: MatDialog, 
    private sm: StorageManagerService, 
    private auth: AuthService,
    private taskService: TaskService,
    private translate: TranslateService,
    private globalDataService: GlobalDataService,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private languageService: LanguageService,
    private fb: FormBuilder,
    private overlay: OverlayContainer
  ) {
    super()
  }

  ngOnInit(): void {
    this.getToken();
    // this.toggleControl.valueChanges.subscribe((darkMode) => {
    //   const darkClassName = 'darkMode';
    //   this.className = darkMode ? darkClassName : '';
    //   if (darkMode) {
    //     this.overlay.getContainerElement().classList.add(darkClassName);
    //   } else {
    //     this.overlay.getContainerElement().classList.remove(darkClassName);
    //   }
    // });
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
    this.getInitData();

    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.onResize();
    this.getActiveLang();

    this.attachSubscriptions(
      this.companyService.getCompanyStream().subscribe(resp => {
        console.log(resp)
        this.company = resp;
        console.log("getCompanyStream");
        if (this.companyForm.value.companyID != resp.id) {
          this.companyForm.patchValue({
            companyID: resp.id
          })
        }
      })
    )

    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => this.imgRoute = el)
    )
    
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
        if (resp.user_id) {
          this.getGlobalData();
        }
      })
    )
  
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.translate.setDefaultLang(resp.active);
        this.languages = resp.languages;
        this.activeLang = resp.active;
      })
    )

    this.attachSubscriptions(
      this.activatedRoute.queryParams.subscribe(params => {
        if (params.hasOwnProperty('code')) {
          this.invite = "?code=" + this.activatedRoute.snapshot.queryParamMap.get('code');
        }
      })
    )
  }

  getGlobalData() {
    // console.log("getGlobalData", this.isGetGlobalData)
    if (this.isGetGlobalData) {
      return;
    }
    let globalReqData = [
      {
        "path": '/api/content-status/',
        "query": {
          'per-page': '200'
        },
        "method": "GET",
        "body": {[this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')}
      },
      {
        "path": '/api/currency/',
        "query": {
          'per-page': '200'
        },
        "method": "GET",
        "body": {[this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')}
      },
      {
        "path": '/api/operation-status/',
        "query": {
          'per-page': '200'
        },
        "method": "GET",
        "body": {[this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')}
      }
    ];

    this.attachSubscriptions(
      this.taskService.multiRequest(globalReqData).subscribe(resp => {
        this.isGetGlobalData = true;
        // console.log("multiRequest", resp)
        this.globalDataService.contentStatus$.next(resp[0].body.slice())
        this.globalDataService.currencies$.next(resp[1].body.slice())
        this.globalDataService.operationStatus$.next(resp[2].body.slice())
      })
    )
  }

  selectLang(lang) {
    this.activeLang = lang;
    this.attachSubscriptions(
      this.languageService.setLanguage(lang).subscribe(resp => {
        if (resp.success) {
          this.languageService.languageData$.next({
            active: lang,
            languages: this.languages
          })
          this.translate.use(lang)
        }
      })
    )
  }

  closeAllDialogs(e?) {
    if (e) {
      e.stopPropagation();
      e.preventDefault()
    }
    this.dialog.closeAll()
  }

  menuHref(e, url, bool) {
    e.preventDefault()
    console.log(this.host + url + `&returnUrl=${window.location.href}`)
    console.log(this.host + url + `&returnUrl=${window.location.origin}`)

    if (bool) {
      window.location.href = this.host + url + `&returnUrl=${window.location.href}`;
    } else {
      window.location.href = this.host + url + `?returnUrl=${window.location.origin}`;
    }
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  getActiveLang() {
    let langData = this.languageService.getLang();
    if (langData != false) {
      this.languageService.languageData$.next(langData);
      this.translate.setDefaultLang(langData.active);
      moment.locale(langData.active)
    } 
  }

  getInitData() {
    this.attachSubscriptions(
      this.auth.getInitData().subscribe(resp => {
        console.log("getInitData", resp)
        if (resp.user) {
          this.auth.$user.next(resp.user);
        }
        if (resp['language']) {
          this.languageService.languageData$.next(resp['language']);
          this.translate.setDefaultLang(resp['language'].active);
        }
        this.isInit = true;
      })
    )
  }

  getToken(flag:boolean = false) {
    this.attachSubscriptions(
      this.auth.getToken({}).subscribe(resp => {
        if (flag && this.user.hasOwnProperty("id") && (this.user_id != this.user.id)) {
          this.refresh();
        }
        this.auth.$userToken.next(resp)
      })
    )
  }
  
  refresh(): void {
    console.log("REFRESH")
    this.clearSubscriptions();
    if (this.sub) {
      this.sub.unsubscribe()
    }
    window.location.reload();
  }

  showDialog(): void {
    this.dialog.open(SampleDialogComponent,
      {
        width: '550px'
      });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
